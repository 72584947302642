import React, { useState } from "react";
import { graphql } from "gatsby";
import _ from "lodash";
// import Truncate from "react-truncate";

import {
  Layout,
  AppImage,
  DiagonalArrowIcon,
  FilterSliderSection,
  LogoIcon,
  Modal,
} from "../components";

const Team = ({ data }) => {
  const teamPageSlides = _.sortBy(
    _.get(data, "allContentfulTeamPageSlide.nodes"),
    "order"
  );
  const allTeamMembersArrays = _.map(teamPageSlides, "teamMembers");

  const [selected, setSelected] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [modalTeamMember, setModalTeamMember] = useState({});

  const handleClick = (modalTeamMember) => {
    if (_.get(modalTeamMember, "description")) {
      setShowModal(!showModal);
      modalTeamMember && setModalTeamMember(modalTeamMember);
    } else if (_.get(modalTeamMember, "url")) {
      window.open(
        _.get(modalTeamMember, "url"),
        "_blank",
        "noopener,noreferrer"
      );
    }
  };

  const filters = teamPageSlides.map((slide, index) => {
    let combineOffsets = 0;
    teamPageSlides.forEach((previousSlide) => {
      if (previousSlide.order < slide.order) {
        combineOffsets = combineOffsets + parseFloat(previousSlide.offset);
      }
    });
    return {
      order: slide.order,
      title: slide.title,
      offset: combineOffsets + index * 24,
    };
  });

  const diagnalArrow = (teamMember, isSelected) => {
    if (isSelected) {
      if (teamMember.url) {
        return (
          <DiagonalArrowIcon
            href={teamMember.url}
            target="_blank"
            rel="noopener noreferrer"
          />
        );
      }
      return (
        <DiagonalArrowIcon
          className="arrow-link"
          role="button"
          aria-label={"More Information"}
          tabIndex={0}
          onClick={() => handleClick(teamMember)}
          onKeyPress={() => handleClick(teamMember)}
        />
      );
    }
    return;
  };

  const teamCard = (teamMember, index, length, isSelected) => {
    return (
      <div
        className={`team-card${
          index + 1 === length
            ? (index + 1) % 4 === 3
              ? " four-remainder-three"
              : (index + 1) % 4 === 2
              ? " four-remainder-two"
              : (index + 1) % 4 === 1
              ? " four-remainder-one"
              : ""
            : ""
        }${
          index + 1 === length
            ? (index + 1) % 3 === 2
              ? " three-remainder-two"
              : (index + 1) % 3 === 1
              ? " three-remainder-one"
              : ""
            : ""
        }${
          index + 1 === length
            ? (index + 1) % 2 === 1
              ? " two-remainder-one"
              : ""
            : ""
        }`}
      >
        {teamMember && (
          <div className="card-contents">
            <div
              className={`image-container${
                teamMember.description || teamMember.url ? " click" : ""
              }`}
              role="button"
              aria-label="More Information"
              tabIndex={-1}
              onClick={() => handleClick(teamMember)}
              onKeyPress={() => handleClick(teamMember)}
            >
              {teamMember.image ? (
                <AppImage image={teamMember.image} />
              ) : (
                <LogoIcon />
              )}
            </div>
            <div className="text-container">
              <div className="name small">{teamMember.name}</div>
              {teamMember.title && (
                <div key={teamMember} className="title small">
                  {teamMember.title}
                </div>
              )}
            </div>
            {diagnalArrow(teamMember, isSelected)}
          </div>
        )}
      </div>
    );
  };

  const teamMembersContainers = (mobile) => {
    return allTeamMembersArrays.map((teamMembersArray, index) => {
      const isSelected = selected === index;
      if (!_.filter(teamMembersArray, "image").length) {
        return (
          <div
            key={index}
            className={`filter-slider-child team-list container ${
              mobile ? " mobile" : " desktop"
            }${isSelected ? " selected" : ""}`}
          >
            {teamMembersArray.map((teamMember, index) => (
              <div className="info-row" key={index}>
                <div className="details">
                  <p className="name">{teamMember.name}</p>
                  <p className="title">{teamMember.title}</p>
                </div>
                {diagnalArrow(teamMember, isSelected)}
              </div>
            ))}
          </div>
        );
      }
      return (
        <div
          key={index}
          className={`filter-slider-child team-members container flex row wrap${
            mobile ? " mobile" : " desktop"
          }${selected === index ? " selected" : ""}`}
        >
          {teamMembersArray.map((teamMember, memberIndex) => {
            return teamCard(
              teamMember,
              memberIndex,
              teamMembersArray.length,
              isSelected
            );
          })}
        </div>
      );
    });
  };

  return (
    <Layout className="team" pageData={_.get(data, "contentfulPageData")}>
      <FilterSliderSection
        filters={filters}
        sliderChildren={teamMembersContainers(true)}
        selected={selected}
        setSelected={setSelected}
      >
        {teamMembersContainers(false)}
      </FilterSliderSection>
      {showModal && (
        <Modal showModal={showModal} handleClick={() => setShowModal(false)}>
          <div className="team-modal container flex tablet">
            <div className="info-container mobile">
              <p className="h3 name">{modalTeamMember.name}</p>
              {modalTeamMember.title && (
                <p className="title">{modalTeamMember.title}</p>
              )}
            </div>
            <AppImage image={modalTeamMember.image} />
            <div className="info-container">
              <b className="h3 name desktop">{modalTeamMember.name}</b>
              {modalTeamMember.title && (
                <p className="title desktop">{modalTeamMember.title}</p>
              )}
              <div
                className="description"
                dangerouslySetInnerHTML={{
                  __html: _.get(
                    modalTeamMember,
                    "description.childMarkdownRemark.html"
                  ),
                }}
              />
            </div>
          </div>
        </Modal>
      )}
    </Layout>
  );
};

export default Team;

export const query = graphql`
  query teamQuery {
    contentfulPageData(title: { eq: "Team" }) {
      title
      description
      image {
        file {
          url
        }
      }
      header
    }
    allContentfulTeamPageSlide {
      nodes {
        order
        title
        offset
        teamMembers {
          ... on ContentfulTeamAdvisoryBoard {
            name
            title
            url
          }
          ... on ContentfulTeamBoardOfDirectors {
            name
            description {
              childMarkdownRemark {
                html
              }
            }
            image {
              title
              fluid(maxWidth: 400, maxHeight: 400, quality: 90) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
          ... on ContentfulTeamDevelopmentDiscoveryCommercial {
            name
            title
            description {
              childMarkdownRemark {
                html
              }
            }
            image {
              title
              fluid(maxWidth: 400, maxHeight: 400, quality: 90) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
          ... on ContentfulTeamLeadership {
            name
            title
            description {
              childMarkdownRemark {
                html
              }
            }
            image {
              title
              fluid(maxWidth: 400, maxHeight: 400, quality: 90) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
